<template>
  <div class="bookable-details py-8">
    <BookableAppbar />

    <v-row class="mx-4">
      <v-col cols="12" class="ma-0 pa-0">
        <ViewingTitle :title="displayName" class="bookable-details__title" />
        <DeleteOrRestore
          v-if="hasDeletePermission"
          :model="bookable"
          name="service & space"
          redirect="/services-and-spaces"
        />

        <div class="d-flex mb-6">
          <v-tabs hide-slider class="bookable-info-tabs">
            <v-tab class="pa-0" v-for="(item, key) in tabItems" :key="key">
              {{ item.tab }}
            </v-tab>

            <v-tab-item class="col-sm-12 col-xs-12 mt-6 pa-0">
              <v-divider class="bookable-details__divider"></v-divider>

              <BookableForm
                class="bookable-details__form"
                :bookable="bookable"
              />
            </v-tab-item>
          </v-tabs>
        </div>
      </v-col>
    </v-row>

    <Loading :value="loading" />
  </div>
</template>
<script>
/**
 * ==================================================================================
 * View Bookable
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import ViewingTitle from '@/components/common/ViewingTitle'
import BookableForm from '@/views/Home/Bookables/components/BookableForm'
import BookableAppbar from '@/views/Home/Bookables/components/BookableAppbar'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Loading from '@/components/common/Loading'
import DeleteOrRestore from '@/components/fields/DeleteOrRestore'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'

export default {
  name: 'BookableDetails',

  components: {
    ViewingTitle,
    BookableAppbar,
    BookableForm,
    Loading,
    DeleteOrRestore,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  data() {
    return {
      loading: false,
      tabItems: [{ tab: 'Info' }],
    }
  },

  computed: {
    ...mapState({
      bookable: (state) => state.bookable.bookableDetails,
      permissions: (state) => state.auth.permissions,
    }),

    displayName() {
      return this.bookable ? this.bookable.title : null
    },

    hasDeletePermission() {
      return validatePermissions(
        [PERMISSION.BOOKABLES_DELETE],
        this.permissions
      )
    },
  },

  methods: {
    ...mapActions({
      getBookableDetails: 'bookable/getBookableDetails',
    }),

    ...mapMutations({
      clearBookableDetails: 'bookable/clearBookableDetails',
    }),

    async getBookable() {
      this.loading = true
      await this.getBookableDetails(this.$route.params.id)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },

  created() {
    this.getBookable()
  },

  destroyed() {
    this.clearBookableDetails()
  },

  watch: {
    $route() {
      this.getBookable()
    },
  },
}
</script>

<style lang="scss" scoped>
.bookable-details {
  &__title {
    padding-right: 10px;
  }

  &__divider {
    max-width: 400px;
  }

  &__uploader {
    //
  }

  @media (max-width: 768px) {
    &__divider {
      max-width: 100%;
    }
  }
}
</style>
